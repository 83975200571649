import React, { useEffect } from "react";
import { Grid, Alert, AlertBanner } from "@cpchem/covalence-ui";
import { Header } from "./components/header";
import { Route, Routes } from "react-router-dom";
import { RheometerPage, DocumentationPage } from "@pages";
import { useFlags } from "launchdarkly-react-client-sdk";
import { App_Container_Elements } from "./elements";
import { getTimeUntilRefresh } from "@utilities/get-time-until-refresh";
import Config from "@config";

import "./app.scss";

function App(): JSX.Element {
    const { customAlertBanner } = useFlags();

    useEffect(() => {
        const timeUntilRefresh = getTimeUntilRefresh(
            Config.autoRefreshTargetTime
        );

        const timeout = setTimeout(() => {
            window.location.reload();
        }, timeUntilRefresh);

        return () => clearTimeout(timeout);
    }, []);

    return (
        <Grid className="app">
            <Header className="header" applicationName="Digital Rheometer" />
            <div
                className="alert-banner-container"
                data-testid={App_Container_Elements.Alert_Banner}
            >
                {customAlertBanner && customAlertBanner.showCustomAlert && (
                    <Alert
                        className="custom-alert-banner"
                        severity={customAlertBanner.status}
                        title={customAlertBanner.statusText}
                        message={customAlertBanner.text}
                    />
                )}
                <AlertBanner className="alert-banner" />
            </div>
            <div className="content">
                <Routes>
                    <Route path="/" element={<RheometerPage />} />
                    <Route
                        path="/documentation"
                        element={<DocumentationPage />}
                    />
                </Routes>
            </div>
        </Grid>
    );
}

export default App;
