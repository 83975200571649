import { AuthManager } from "@cpchem/azure-auth";
import {
    FetchOptionCredential,
    FetchOptionMode,
    FetchOptions,
    FetchOptionMethod,
    AuthServiceKey,
    ServiceLocator
} from "@services";
import { FetchinterceptorService } from "./fetch-interceptor-service";
import { getTraceParent } from "@cpchem/logging";

export class AppIngishtsFetchinterceptorImplementation
    implements FetchinterceptorService
{
    private readonly authService: AuthManager;
    constructor() {
        this.authService = ServiceLocator.get(AuthServiceKey);
    }
    async getFetchOptionsAsync(
        base: string,
        scopes: string[],
        method: FetchOptionMethod,
        mode: FetchOptionMode,
        credentials: FetchOptionCredential
    ): Promise<FetchOptions> {
        const token = await this.authService.getApiToken(scopes);
        const headers = new Headers();
        const bearer = `Bearer ${token}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json");
        headers.append("traceparent", getTraceParent());

        return {
            method,
            headers,
            credentials,
            mode
        };
    }
}
