import React from "react";
import { createRoot } from "react-dom/client";
import {
    ThemeProvider,
    AlertBannerProvider,
    NotificationProvider,
    ToastProvider
} from "@cpchem/covalence-ui";
import AppContainer from "./containers/app";
import { RheometerServiceKey, ServiceLocator, AuthServiceKey } from "@services";
import { AuthManager } from "@cpchem/azure-auth";
import { AuthenticationBoundary } from "@cpchem/azure-auth-react";
import config from "@config";
import { RheometerServiceImplementation } from "@services/rheometer/implementation";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./hooks";
import { configureLogging } from "@utilities/log/configure-logging";
import {
    configureAppInsights,
    configureAppInsightsLogging
} from "@utilities/log/configure-app-insights";
import {
    AppIngishtsFetchinterceptorImplementation,
    FetchServiceKey
} from "@services/fetchInterceptor";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import { LoginScreen } from "./containers/app/components/login-screen";
import { UnauthenticatedPage } from "./pages/unathenticated-page";
import "./index.scss";

// Configure Logging Sinks
configureLogging(config.env);
const appInsights = configureAppInsights(config.logging.appInsights);
configureAppInsightsLogging(appInsights);

const authSettings = {
    clientId: config.msal.auth.clientId,
    authority: config.msal.auth.authority ?? "",
    redirectUri: config.msal.auth.redirectUri ?? "",
    scopes: [...config.api.rheometer.scopes],
    domainHint: "cpchem.com"
};
let authManagerInstance: AuthManager;

if (!config.connected) {
    authManagerInstance = AuthManager.getInstance();
    authManagerInstance.loadConfiguration("Dev");
    if (!window.Cypress) {
        // Cypress expected to Mock it's own data calls.
        // Faked APIs
        const { worker } = await import("./api-mocks/browser");
        worker.start();
    }
} else {
    let domainHint = "cpchem.com";
    if (config.env === "development") {
        domainHint = "jonthenerd3.onmicrosoft.com";
    }
    authSettings.domainHint = domainHint;
    authManagerInstance = AuthManager.getInstance();
    authManagerInstance.loadConfiguration("MSAL", authSettings);
}

// Configure Services in Service Locator
ServiceLocator.set(AuthServiceKey, authManagerInstance);
ServiceLocator.set(
    FetchServiceKey,
    new AppIngishtsFetchinterceptorImplementation()
);
ServiceLocator.set(RheometerServiceKey, new RheometerServiceImplementation());

const container = document.getElementById("root") as HTMLElement;

const root = createRoot(container);

const App = withLDProvider({
    clientSideID: config.launchDarklyClientSideId,
    reactOptions: {
        useCamelCaseFlagKeys: true
    }
})(AppContainer);

root.render(
    <React.StrictMode>
        <ThemeProvider defaultTheme="dark">
            <NotificationProvider>
                <ToastProvider>
                    <AlertBannerProvider>
                        <AuthProvider appInsights={appInsights}>
                            <Router>
                                <AuthenticationBoundary
                                    showOnUnknown={<LoginScreen />}
                                    showOnAuthenticated={<App />}
                                    showOnUnAuthenticated={
                                        <UnauthenticatedPage />
                                    }
                                />
                            </Router>
                        </AuthProvider>
                    </AlertBannerProvider>
                </ToastProvider>
            </NotificationProvider>
        </ThemeProvider>
    </React.StrictMode>
);
